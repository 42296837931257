import React from 'react';
import {
    Grid,
    Typography,
    FormControlLabel,
    FormControl,
    RadioGroup,
    FormLabel,
    Radio,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    makeStyles,
    TableContainer,
    Paper,
    TableHead,
    Table,
    TableBody, TableRow, TableCell, CircularProgress
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {DatePickerField, InputField, RadioField} from '../../FormFields';
import {
    KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from "date-fns/locale/de"
import {useField} from "formik";
import axios from "axios";
import {globals} from "../../globals";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    table: {
        minWidth: 500,
    },
    tableRow: {
        backgroundColor: theme.palette.secondary.main
    },
    tableRowNotToday: {
        backgroundColor: ""
    },
    accordion: {
        marginTop: "15px"
    }
}));


export default function PatientForm(props) {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [radioValue, setValue] = React.useState("patient");

    const [times, setTimes] = React.useState([]);

    React.useEffect(() => {
        const config = {
            method: 'get',
            url: globals.API_URL + '/kh/times',
            headers: {'Accept': 'application/json', 'Access-Control-Allow-Origin' : window.location.origin}
        };

        axios(config)
            .then(response => response.data)
            .then(responseData => {
                setTimes(responseData)
                console.log(times)
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    const {
        formField: {radio},
    } = props;

    return (
        <React.Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <Typography variant="h6" gutterBottom>
                    Mein Anliegen
                </Typography>

                <RadioField name={radio.name} label={radio.label} label1={radio.label1} label2={radio.label2} {...props}
                            times={times}/>

                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Besuchszeiten</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label={"besuchsZeiten"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tag</TableCell>
                                        <TableCell>Von</TableCell>
                                        <TableCell>Bis</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(times.length !== 0) ? times.map((day) => (
                                        <TableRow row={day.id}
                                                  className={(new Date().getDay() !== day.id) ? "" : classes.tableRow}>
                                            <TableCell>{day.day}</TableCell>
                                            {
                                                (day.besucher === 1) ?
                                                    <>
                                                        <TableCell>{day.start.split(":").slice(0, -1).join(':')}</TableCell>
                                                        <TableCell>{day.end.split(":").slice(0, -1).join(':')}</TableCell>
                                                    </> :
                                                    <>
                                                        <TableCell>Geschlossen</TableCell>
                                                        <TableCell>Geschlossen</TableCell>
                                                    </>
                                            }
                                        </TableRow>
                                    )) : <CircularProgress color={"primary"}/>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>

                {/*
            <FormControl component="fieldset">
              <RadioGroup aria-label="type" name={radio.name} value={radioValue} onChange={handleRadioChange}>
                <Tooltip title={"Ich besuche einen Patienten"}>
                    <FormControlLabel value={"patient"} control={<Radio />} label={radio.label} />
                </Tooltip>
                <Tooltip title={"Ich habe einen eigenen Termin"}>
                    <FormControlLabel value={"appointment"} control={<Radio />} label={radio.label1} />
                </Tooltip>
                <FormControlLabel value={"other"} control={<Radio />} label={radio.label2} />
              </RadioGroup>
            </FormControl>
*/}


            </MuiPickersUtilsProvider>
        </React.Fragment>
    );
}

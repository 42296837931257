import html2canvas from 'html2canvas'

export const downloadImage = (fileName) => {
    const screenshot = document.getElementById('divToPrint');

    html2canvas(screenshot)
        .then(canvas => {
            document.body.appendChild(canvas)
            canvas.style.display = 'none'
            return canvas
        })
        .then(canvas => {
            const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
            const a = document.createElement('a')
            a.setAttribute('download', fileName + '.png')
            a.setAttribute('href', image)
            a.click()
            canvas.remove()
        })
}
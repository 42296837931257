import React from 'react';
import {Button, ButtonGroup, CircularProgress, Grid, Typography} from '@material-ui/core';


function CheckoutSuccess(data) {

    const [open, setOpen] = React.useState(false)
    const isLocal = window.location.pathname.includes("localform")

    React.useEffect(() => {
        if(!isLocal) {
            localStorage.setItem('UID', JSON.stringify(data.qrJSON))
        }
        setTimeout(() => {
            window.location.reload();
        }, isLocal ? 5000 : 1000)
    }, [])

    const handleClickOpen = () => {
        console.log(open)
        setOpen(true);
    };


    return (
        <React.Fragment>
            <Typography variant="h5" align="center">
                {(isLocal) ? "Die Tür wird gleich geöffnet" : "Ihr QR-Ticket wird erstellt"}
            </Typography>
            <Typography component="h1" variant="h4" align="center">
                <div style={{marginTop: "15px"}}>
                    <CircularProgress color={"primary"} size={150}/>
                </div>
            </Typography>
            {/* <Print code={JSON.stringify(data.qrJSON)} open={true} />*/}
            {/* <ConfirmDialogue onConfirm={() => window.print()}/>*/}
        </React.Fragment>
    );
}

export default CheckoutSuccess;

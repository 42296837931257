import React from 'react';
import {Typography, Grid} from '@material-ui/core';
import useStyles from './styles';

function PatientDetails(props) {
    const {formValues} = props;
    const classes = useStyles();
    const {radio, date, freetext} = formValues;

    return (

        window.location.hostname.includes("hycos-manager") ?
            <Grid item xs={12} sm={6}>
                <Typography gutterBottom>{`${date.split("T")[0]}`}</Typography>
            </Grid>
            :
            <Grid item xs={12} sm={6}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                    {radio}
                </Typography>
                <Typography gutterBottom>{`${freetext}`}</Typography>
                <Typography gutterBottom>{`${date.split("T")[0]}`}</Typography>
            </Grid>
    );
}

export default PatientDetails;

import React, {useCallback, useState} from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    CircularProgress, Checkbox, FormGroup, FormControlLabel, FormControl, FormLabel, FormHelperText, useMediaQuery
} from '@material-ui/core';
import {Formik, Form} from 'formik';

import CovidForm from "./Forms/CovidForm";
import AddressForm from './Forms/AddressForm';
import PatientForm from './Forms/PatientForm';
import ReviewOrder from './ReviewOrder';
import CheckoutSuccess from './CheckoutSuccess';


import validationSchema from './FormModel/validationSchema';
import checkoutFormModel from './FormModel/checkoutFormModel';
import formInitialValues from './FormModel/formInitialValues';

import useStyles from './styles';
import axios from "axios";
import * as PropTypes from "prop-types";
import {globals} from "../globals";

const steps = ['Covid Status', 'Besucher', 'Besuchsdaten', 'Bestätigen'];
const {formId, formField} = checkoutFormModel;

function _renderStepContent(step) {
    switch (step) {
        case 0:
            return <CovidForm formField={formField}/>
        case 1:
            return <AddressForm formField={formField}/>;
        case 2:
            return <PatientForm formField={formField}/>;
        case 3:
            return <ReviewOrder/>;
        default:
            return <div>Not Found</div>;
    }
}

function CheckBoxOutlineBlankIcon(props) {
    return null;
}

CheckBoxOutlineBlankIcon.propTypes = {fontSize: PropTypes.string};
export default function CheckoutPage() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [qr, setQR] = useState();
    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const [reCaptcha, setReCaptcha] = useState({token: "", load: false, expired: false});

    const [dsgvo1, setDgsvo1] = useState(false);
    const [dsgvo2, setDgsvo2] = useState(false);
    const [submitted, setSubmitted] = useState(false)

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

   /* React.useEffect(() => {
           window.addEventListener('beforeunload', handleUnload)

        return () => window.removeEventListener('beforeunload', handleUnload)
    }, [submitted]) */

    const handleUnload = (e) => {
        let message = "";

        (e || window.event).returnValue = message; //Gecko + IE
        return message;
    }

    function submitForm(values, actions) {

        let url = "https://klinikum-canticsoft.de/api/kh/register"
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // dev code
            url = 'http://127.0.0.1:8000/api/kh/register'
        }

        let config = {
            method: 'post',
            url: globals.API_URL + "/kh/register",
            headers: {
                'g-recaptcha-response': reCaptcha.token
            },
            data: values
        };

        axios(config).then((response) => {

            actions.setSubmitting(false)
            setSubmitted(true)
            setQR({"UID": response.data.UID})
            //localStorage.setItem(response.data.date, response.data.UID)
            setActiveStep(activeStep + 1)
        }).catch(error => {
            actions.setSubmitting(false)
            console.log(reCaptcha.token)
            alert(error)
        })
    }

    function _handleSubmit(values, actions) {
        if (isLastStep) {
            submitForm(values, actions);
            localStorage.setItem("data", JSON.stringify(values))
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
        setReCaptcha({token: "", load: false, expired: false})
    }

    const DSGVO = () => {

        const handledsgvo1Change = (event) => {
            setDgsvo1(event.target.checked)
        }

        const handledsgvo2Change = (event) => {
            setDgsvo2(event.target.checked)
        }

        const datenschutz = (window.location.hostname.includes("hycos-manager.de") ? "" : "https://www.klinikum-altenburgerland.de/das-klinikum/stabsstelle-datenschutz/datenschutz.html")
        return (
            <FormControl component="fieldset" style={{marginTop: "15px"}}>
                <FormLabel component="legend" style={{marginTop: "15px"}}>Datenschutz</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dsgvo1}
                                onChange={handledsgvo1Change}
                                name="dsgvo1"
                            />
                        }
                        label={<Typography variant="body2" gutterBottom>
                            Hiermit bestätige ich, die Widerrufsbelehrung und die <a
                            href={datenschutz}
                            target="_blank">Datenschutzerklärung</a> mit den Informationen zur Datenverarbeitung zur
                            Kenntnis genommen zu haben.
                        </Typography>}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dsgvo2}
                                onChange={handledsgvo2Change}
                                name="dsgvo2"
                            />
                        }
                        label={<Typography variant={"body2"} gutterBottom>Wir weisen Sie darauf hin, dass am Tag Ihres
                            Besuches folgende Voraussetzungen gegeben sein müssen:</Typography>}
                    />
                </FormGroup>
                <FormHelperText>
                    <Typography variant={"caption"} gutterBottom>
                        • Sie haben keine akuten Krankheitssymptome, wie Fieber, Husten und Atemnot. <br/>
                        • Ihre Körpertemperatur ist nicht erhöht. <br/>
                        • Sie hatten in den letzten 14 Tagen wissentlich keinen engen Kontakt zu einer anderen Person
                        mit positivem Nachweis von Covid-19. <br/>
                    </Typography>
                </FormHelperText>
            </FormControl>
        )

    }

    return (
        <React.Fragment>
            <Typography component="h1" variant="h4" align="center">
                Besucher Anmeldung
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel={isSmall}>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <React.Fragment>
                {activeStep === steps.length ? (
                    <CheckoutSuccess qrJSON={qr}/>
                ) : (
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={currentValidationSchema}
                        onSubmit={_handleSubmit}
                    >
                        {({isSubmitting}) => (
                            <Form id={formId}>
                                {_renderStepContent(activeStep)}

                                {isLastStep ? <DSGVO/> : null}

                                <div className={classes.buttons}>
                                    <div className={classes.wrapper}>
                                        {activeStep !== 0 && (
                                            <Button onClick={_handleBack} className={classes.button}>
                                                Zurück
                                            </Button>
                                        )}
                                        <Button
                                            disabled={isSubmitting || isLastStep && !(dsgvo1 && dsgvo2)}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                        >
                                            {isLastStep ? 'Anmelden' : 'Weiter'}
                                        </Button>

                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </React.Fragment>
        </React.Fragment>
    );
}

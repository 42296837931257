let host = window.location.hostname
let url = "https://"+ host +"/api"

if(host.includes("hycos-manager")) {
    url = "https://api.hycos-manager.de"
}

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    url = "http://192.168.178.43:8000/api"
}

export const globals = {
    API_URL: url,
}

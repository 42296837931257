export default {
    formId: 'checkoutForm',
    formField: {
        firstName: {
            name: 'firstName',
            label: 'Vorname',
            requiredErrorMsg: 'Vorname ist erforderlich'
        },
        lastName: {
            name: 'lastName',
            label: 'Nachname',
            requiredErrorMsg: 'Nachname ist erforderlich'
        },
        address: {
            name: 'address',
            label: 'Adresse ',
            requiredErrorMsg: 'Adresse ist erforderlich'
        },
        city: {
            name: 'city',
            label: 'Stadt ',
            requiredErrorMsg: 'Heimatstadt ist erforderlich'
        },
        zipcode: {
            name: 'zipcode',
            label: 'PLZ ',
            requiredErrorMsg: 'PLZ ist erforderlich',
            invalidErrorMsg: 'PLZ is not valid (e.g. 70000)'
        },
        email: {
            name: 'email',
            label: 'E-Mail Adresse',
            requiredErrorMsg: 'E-Mail Adresse ist erforderlich'
        },
        phone: {
            name: 'phone',
            label: 'Telefon ',
            requiredErrorMsg: 'Telefonnummer ist erforderlich'
        },
        firstNamePatient: {
            name: 'firstNamePatient',
            label: 'Vorname ',
            requiredErrorMsg: 'Patient Vorname ist erforderlich'
        },
        lastNamePatient: {
            name: 'lastNamePatient',
            label: 'Nachname ',
            requiredErrorMsg: 'Patient Nachname ist erforderlich'
        },
        namePatient: {
            name: 'namePatient',
            label: 'Names Nachname des Patienten ...',
            requiredErrorMsg: 'Patient Name ist erforderlich'
        },
        stationPatient: {
            name: 'stationPatient',
            requiredErrorMsg: 'Station ist erforderlich'
        },
        date: {
            name: 'date',
            label: 'Termin - Datum',
            requiredErrorMsg: 'Datum ist erforderlich'
        },
        freitext: {
            name: 'freetext',
            other: 'Bsp. Firmenname ...',
            patient: 'Vorname und Nachname des Patienten',
            appointment: 'Bsp. Ambulanz ...',
            requiredErrorMsg: 'Freitext ist erforderlich'
        },
        radio: {
            name: 'radio',
            label: 'Patient besuchen',
            label1: 'eigener Termin',
            label2: 'Elterninfoabend',
        },
        radioCovid: {
            name: "radioCovid",
            label1: "Ich bin vollständig geimpft",
            label2: "Ich habe ein negatives Testergebnis",
            label3: "Ich bin genesen",
            label2gPlus: "Ich habe ein negatives Testergebnis",
            requiredErrorMsg: 'Bitte kreuzen sie Zutreffendes an'
        }

    }
};

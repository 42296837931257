import React from 'react';
import {at} from 'lodash';
import {useField} from 'formik';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText, Grid, Radio, RadioGroup, Tooltip
} from '@material-ui/core';
import {DatePickerField, InputField} from "./index";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import deLocale from "date-fns/locale/de"


export default function RadioField(props) {
    const {name, label, label1, label2, times, ...rest} = props;
    const [field, meta, helper] = useField(props);
    const {setValue} = helper;

    const {
        formField: {freitext, namePatient, stationPatient, date, radio}
    } = props;

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return <FormHelperText>{error}</FormHelperText>;
        }
    }

    function _onChange(e) {
        console.log(rest)
        setValue(e.target.value);
    }


    function inputConf() {

        if (field.value === "Patient besuchen") {
            return freitext.patient
        } else if ((field.value === "eigener Termin")) {
            return freitext.appointment
        }

        return freitext.other
    }



    const display = field.value !== "Patient besuchen" ? "none" : "";
    const isLocal = window.location.pathname.includes("localform");

    return (
        <FormControl {...rest}>

            <RadioGroup aria-label="type" name={name} value={field.value} onChange={_onChange}>
                <Tooltip title={"Ich besuche einen Patienten"}>
                    <FormControlLabel value={"Patient besuchen"} control={<Radio/>} label={radio.label}/>
                </Tooltip>
                {/*<Tooltip title={"Ich habe einen eigenen Termin"}>
                    <FormControlLabel value={"eigener Termin"} control={<Radio/>} label={radio.label1} disabled/>
                </Tooltip>*/}
                <FormControlLabel value={"Elterninfoabend"} control={<Radio/>} label={radio.label2} />
            </RadioGroup>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>

                <Grid container spacing={6}>
                    <Grid item xs={12} style={{display: display}}>
                        <InputField
                            name={freitext.name}
                            label={inputConf()}
                            fullWidth
                            required={display !== "none"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePickerField name={date.name} label={date.label} times={times} fullWidth/>
                    </Grid>
                </Grid>

                {_renderHelperText()}
            </MuiPickersUtilsProvider>

        </FormControl>
    );
}

import React from 'react';
import {useFormikContext} from 'formik';
import {Typography, Grid} from '@material-ui/core';
import BesucherDetails from './BesucherDetails';
import PatientDetails from './PatientDetails';

export default function ReviewOrder() {
    const {values: formValues} = useFormikContext();
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Überprüfen
            </Typography>
            <Grid container spacing={2}>
                <BesucherDetails formValues={formValues}/>
                <PatientDetails formValues={formValues}/>
            </Grid>
        </React.Fragment>
    );
}

import React from 'react';
import {
    Grid,
    Typography,
    FormControlLabel,
    FormControl,
    RadioGroup,
    FormLabel,
    Radio,
    Tooltip, FormHelperText, Button, IconButton, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core';
import {DatePickerField, InputField, RadioField} from '../../FormFields';
import {
    KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from "date-fns/locale/de"
import {useField} from "formik";
import {at} from "lodash";
import {HelpOutline, InfoRounded, LiveHelp} from "@material-ui/icons";
import DialogContentText from "@material-ui/core/DialogContentText";

function CovidRadioField(props) {
    const {name, label, label1, label2, ...rest} = props;
    const [field, meta, helper] = useField(props);
    const {setValue} = helper;
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const {
        formField: {radioCovid}
    } = props;

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return <FormHelperText>{error}</FormHelperText>;
        }
    }

    function _onChange(e) {
        console.log(rest)
        setValue(e.target.value);
    }

    const tooltipVaccinated = <Typography variant={"caption"}>
        Als vollständig geimpft gilt jede Person ab dem 15. Tag nach : <br/>
        a. der Erstimpfung mit dem Impfstoff von Johnson & Johnson oder <br/>
        b. der Zweitimpfung mit einem anderen Impfstoff, der vom Paul-Ehrlich-Institut auf seiner Internetseite genannt
        ist <br/>
    </Typography>

    const toolTipNegative = <Typography variant={"caption"}>
        (nicht älter als 24h) auf das Vorliegen einer Infektion mit dem Coronavirus SARS-CoV-2, ein negatives
        Testergebnis eines PCR-Tests (nicht älter als 48h) oder eine Bescheinigung über das negative Ergebnis eines
        Antigenschnelltests (nicht älter als 24h) und den konkreten Zeitpunkt der Testung durch eine entsprechende
        Teststelle.
    </Typography>

    const tooltipHealthy = <Typography variant={"caption"}>
        Als genesen gilt, wer mittels <br/>
        a. eines positiven PCR-Testergebnisses oder <br/>
        b. einer ärztlichen oder behördlichen Bescheinigung, welche sich auf eine mittels PCR-Test bestätigte
        durchgemachte Infektion mit dem Coronavirus SARS-CoV-2 stützt, eine mindestens 28 Tage und nicht länger als
        sechs Monate zurückliegende Infektion mit dem Coronavirus SARS-CoV-2 nachweisen kann. <br/>
    </Typography>

    const LabelButton = (text, toolTip, hasThis) => {
        const [open, setOpen] = React.useState(false);

        return (
            <Grid container
                  direction="row"
                  justify="flex-start"
                  alignItems="center">
                <Tooltip title={toolTip}>
                    <Typography variant={(isSmall) ? "body2" : "body1"}>{text}</Typography>
                </Tooltip>
                <IconButton color={"primary"} onClick={() => {
                    setOpen(!open)
                }}>
                    <HelpOutline fontSize={"small"}/>
                </IconButton>

                <Dialog
                    open={open}
                    onClose={() => {
                        setOpen(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{text}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {toolTip}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(!open)} color="primary" variant={"outlined"} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }

    return (
        <FormControl {...rest}>
            <RadioGroup aria-label="type" name={name} value={field.value} onChange={_onChange}>
                <FormControlLabel value={"Vollständig geimpft."} control={<Radio/>}
                                  label={LabelButton(radioCovid.label1, tooltipVaccinated, false)}/>
                <FormControlLabel value={"Negativer Test"} control={<Radio/>}
                                  label={LabelButton(radioCovid.label2gPlus, toolTipNegative, false)}/>
                <FormControlLabel value={"Genesen"} control={<Radio/>}
                                   label={LabelButton(radioCovid.label3, tooltipHealthy, true)}/>
                <Typography variant={"caption"}>Der Zutritt zum Klinikum ist nur mit einem entsprechenden Nachweis möglich. Dieser wird am Eingang kontrolliert.</Typography>
            </RadioGroup>
        </FormControl>
    );
}

export default function CovidForm(props) {
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [radioValue, setValue] = React.useState("patient");

    React.useEffect(() => {
        console.log()
    })

    const {
        formField: {radioCovid}
    } = props;

    const [field, meta, helper] = useField(props);

    return (
        <React.Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <Typography variant="h6" gutterBottom>
                    Bitte bestätigen sie folgendes:
                </Typography>

                <CovidRadioField name={radioCovid.name} label={radioCovid.label} label1={radioCovid.label1}
                                 label2={radioCovid.label2} {...props}/>

            </MuiPickersUtilsProvider>
        </React.Fragment>
    );
}

import React, {useState, useEffect} from 'react';
import {useField} from 'formik';
import Grid from '@material-ui/core/Grid';
import {InputAdornment, IconButton} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event';
import {
    MuiPickersUtilsProvider,
    DateTimePicker, DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from "date-fns/locale/de"
import {globals} from "../globals";
import axios from "axios";

export default function DatePickerField(props) {
    const [field, meta, helper, times] = useField(props);
    const {touched, error} = meta;
    const {setValue} = helper;
    const isError = touched && error && true;
    const {value} = field;
    const [selectedDate, setSelectedDate] = useState(null);
    const [closed, setClosed] = useState([]);

    useEffect(() => {
        fetchClosedDays()
        if (value) {
            const date = new Date(value);
            setSelectedDate(date);
        }

    }, [value]);

    function _onChange(date) {
        if (date) {
            setSelectedDate(date);
            try {
                const ISODateString = date.toISOString();
                setValue(ISODateString);
            } catch (error) {
                setValue(date);
            }
        } else {
            setValue(date);
        }
    }

    const fetchClosedDays = () => {
        const config = {
            method: 'get',
            url: globals.API_URL + '/kh/closed',
        };

        axios(config)
            .then(response => response.data)
            .then(responseData => {
                setClosed(responseData)
                console.log(times)
            })
            .catch(err => {
                console.error(err)
            })
    }

    const disableDate = (date) => {
        return closed.includes(date.getDay());
    }

    return (
        <Grid container>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <DatePicker
                    {...field}
                    {...props}
                    value={selectedDate}
                    onChange={_onChange}
                    error={isError}
                    invalidDateMessage={isError && error}
                    helperText={isError && error}
                    disablePast
                    shouldDisableDate={disableDate}
                    format="dd/MM/yyyy"
                    cancelLabel={React.createElement('span', null, 'Abbrechen')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <EventIcon/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </MuiPickersUtilsProvider>
        </Grid>
    );
}

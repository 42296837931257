import React from 'react';
import {Paper, CssBaseline, useMediaQuery, Box} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/core/styles';

import Header from '../Header';
import Footer from '../Footer';

import {theme, useStyle} from './styles';

export default function MaterialLayout(props) {
    const {children} = props;
    const classes = useStyle();

    return (
        <ThemeProvider theme={theme}>
            <Box displayPrint="none">
                <CssBaseline/>
                <Header/>
                <div className={classes.root}>
                    <Paper className={classes.paper}>{children}</Paper>
                </div>
                <Footer/>
            </Box>

            <Box style={{marginTop: "25px", transform: "scale(1.2,1.2)"}} display={"none"} displayPrint={"block"}>{children}</Box>
        </ThemeProvider>
    );
}

import * as Yup from 'yup';
import moment from 'moment';
import checkoutFormModel from './checkoutFormModel';

const {
    formField: {
        firstName,
        lastName,
        address,
        city,
        zipcode,
        namePatient,
        freitext,
        email,
        phone,
        date,
        radio,
        radioCovid,
    }
} = checkoutFormModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

export default [
    Yup.object().shape({
        [radioCovid.name]: Yup.string().required(`${radioCovid.requiredErrorMsg}`)
    }),
    Yup.object().shape({
        [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
        [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
        [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
        //[email.name]: Yup.string().required(`${address.requiredErrorMsg}`),
        [phone.name]: Yup.string().required(`${address.requiredErrorMsg}`),
        [city.name]: Yup.string()
            .nullable()
            .required(`${city.requiredErrorMsg}`),
        [zipcode.name]: Yup.string()
            .required(`${zipcode.requiredErrorMsg}`)
            .test(
                'len',
                `${zipcode.invalidErrorMsg}`,
                val => val && val.length === 5
            ),
    }),
    Yup.object().shape({
        [radio.name]: Yup.string().required(`${radio.requiredErrorMsg}`),
        [freitext.name]: Yup.string().optional(`${freitext.requiredErrorMsg}`),
        [date.name]: Yup.string().required(`${date.requiredErrorMsg}`),
    })
];

import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {InputField, CheckboxField, SelectField} from '../../FormFields';

export default function AddressForm(props) {
    const {
        formField: {
            firstName,
            lastName,
            address,
            city,
            zipcode,
            phone,
            email
        }
    } = props;
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Besucher Informationen
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <InputField name={firstName.name} label={firstName.label} fullWidth required={true}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField name={lastName.name} label={lastName.label} fullWidth required={true}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField name={email.name} label={email.label} fullWidth required={false}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField name={phone.name} label={phone.label} fullWidth required={true}/>
                </Grid>
                <Grid item xs={12}>
                    <InputField name={address.name} label={address.label} fullWidth required={true}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField name={zipcode.name} label={zipcode.label} fullWidth required={true}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField name={city.name} label={city.label} fullWidth required={true}/>
                </Grid>

            </Grid>
        </React.Fragment>
    );
}

import React from 'react';
import MaterialLayout from './components/Layout/MaterialLayout';
import CheckoutPage from './components/CheckoutPage';
import {Box, Button, ButtonGroup, Grid, makeStyles, Typography, useMediaQuery} from "@material-ui/core";
import QRCode from "qrcode.react";
import {Print} from "./components/Print";
import {downloadImage, printDocument} from "./components/CheckoutPage/CheckoutSuccess/helper";

function App() {
    const [uid] = React.useState(localStorage.getItem('UID'))
    const [open, setOpen] = React.useState(false)

    const WhenUIDexists = () => {

        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
        const usePrint = useMediaQuery('print')

        const text = JSON.parse(localStorage.getItem("data"))

        const useStyles = makeStyles((theme) => ({
            root: {
                display: (!usePrint) ? 'flex' : 'none',
                '& > *': {
                    margin: theme.spacing(1),
                },
            },
        }));

        const classes = useStyles()

        return (
            <div style={{marginBottom: "15px"}}>
                <div id={"divToPrint"}>
                    <Typography variant="h5" align="center">
                        Ihr Ticket
                    </Typography>

                    <Typography variant={"body1"} align={"center"}>
                        <p>Besucher Name: {text?.firstName + " " + text?.lastName}</p>
                        {window.location.hostname.includes("hycos-manager") ? null : <p>Art des Besuchs: {text?.radio} {text?.freetext}</p>}
                        <p>Covid Status: {text?.radioCovid}</p>
                        <p>Datum: {text?.date.split("T")[0]}</p>
                    </Typography>
                    <Typography component="h1" variant="h4" align="center">
                        <div>
                            <QRCode
                                id="qrCodeCanvas"
                                value={uid}
                                size={300}
                                level={"H"}
                                includeMargin={true}
                            />
                        </div>
                    </Typography>
                </div>
                <Box display={"flex"} displayPrint={"none"}>
                <Grid
                    container
                      spacing={12}
                      direction="column"
                      alignItems="center"
                      justify="center"

                >
                    <Grid xs={12}  className={classes.root}>
                        <ButtonGroup orientation={isSmall ? "vertical" : "horizontal"}>
                            <Button onClick={() => downloadImage(text?.firstName + "_" + text?.lastName + "_" + text?.date.split("T")[0])} color="primary" variant={"contained"}>
                                Herunterladen
                            </Button>
                            <Button color={"primary"} variant={"outlined"}
                                    onClick={window.print}>Drucken</Button>
                            <Button color={"primary"} variant={"outlined"} onClick={() => {
                                if (window.confirm("Möchten sie wirklich ein neues Ticket erstellen? Ihr altes wird verworfen, wen sie es nicht gespeichert haben.")) {
                                    localStorage.clear()
                                    window.location.reload()
                                }
                            }}>Neues Ticket</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                </Box>
            </div>
        )

    }

    return (
        <div>
            <MaterialLayout>
                {(uid) ? <WhenUIDexists/> : <CheckoutPage/>}
            </MaterialLayout>
        </div>
    );
}

export default App;

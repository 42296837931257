import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import hycos from "./hycos.png"
import klinikum from  "./klinikum.png"

const logo = window.location.hostname.includes("hycos-manager") ? hycos : klinikum

export default function Header() {
    const classes = useStyles();

    return (
        <AppBar position="absolute" color="default" className={classes.appBar}>
            <Toolbar>
                <img
                    src={logo}
                    alt={"logo"}
                    className={classes.logo}/>
            </Toolbar>
        </AppBar>
    );
}

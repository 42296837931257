import React from 'react';
import {Typography, Grid} from '@material-ui/core';
import useStyles from './styles';

function BesucherDetails(props) {
    const {formValues} = props;
    const classes = useStyles();
    const {firstName, lastName, address, zipcode, city} = formValues;
    return (
        <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
                Besucher
            </Typography>
            <Typography gutterBottom>{`${firstName} ${lastName}`}</Typography>
            <Typography gutterBottom>{`${address}, ${zipcode} ${city}`}</Typography>
        </Grid>
    );
}

export default BesucherDetails;

import checkoutFormModel from './checkoutFormModel';

const {
    formField: {
        firstName,
        lastName,
        address,
        city,
        zipcode,
        namePatient,
        stationPatient,
        email,
        phone,
        date,
        radio,
        radioCovid
    }
} = checkoutFormModel;

export default {
    [firstName.name]: '',
    [lastName.name]: '',
    [address.name]: '',
    [city.name]: '',
    [zipcode.name]: '',
    [namePatient.name]: '',
    [stationPatient.name]: '',
    [email.name]: '',
    [phone.name]: '',
    [date.name]: '',
    [radio.name]: 'Patient besuchen',
    [radioCovid]: ''
};
